import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyComponent } from './company.component';

const routes: Routes = [
  {path: '',    component: CompanyComponent},
  { path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'technology', loadChildren: () => import('./technology/technology.module').then(m => m.TechnologyModule) },
  { path: 'join-us', loadChildren: () => import('./join-us/join-us.module').then(m => m.JoinUsModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }