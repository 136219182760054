import { Component } from '@angular/core';
import * as AOS from 'aos';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'RecruitSolutions';
  showHeaderAndFooter: boolean;

  constructor(private router: Router) {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.showHeaderAndFooter = !this.router.url.includes('/utility');
        }
      }
    );
  }

  ngOnInit() {
    AOS.init();
  }
}
