import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-up-and-running-in-five-mini',
  templateUrl: './get-up-and-running-in-five-mini.component.html',
  styleUrls: ['./get-up-and-running-in-five-mini.component.scss']
})
export class GetUpAndRunningInFiveMiniComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
