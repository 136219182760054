import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResourcesComponent } from './resources.component';

const routes: Routes = [
  {path: '',    component: ResourcesComponent},
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m. FaqModule) },
  { path: 'compare', loadChildren: () => import('./compare/compare.module').then(m => m.CompareModule) },
  { path: 'documentation', loadChildren: () => import('./documentation/documentation.module').then(m => m.DocumentationModule) },
  { path: 'gdpr', loadChildren: () => import('./gdpr/gdpr.module').then(m => m. GdprModule) },
  { path: 'security', loadChildren: () => import('./security/security.module').then(m => m.SecurityModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourcesRoutingModule { }