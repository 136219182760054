import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'why-free', loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule) },
  { path: 'signin', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule) },
  { path: 'demo', loadChildren: () => import('./pages/demo/demo.module').then(m => m.DemoModule) },
  { path: 'sign-up', loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.FreeTrialModule) },
  { path: 'resources', loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule) },
  { path: 'company', loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule) },
  { path: 'features', loadChildren: () => import('./pages/features/features.module').then(m => m.FeaturesModule) },
  { path: 'contact-us', loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'utility', loadChildren: () => import('./pages/utility/utility.module').then(m => m.UtilityModule) }
];

@NgModule({
  declarations: [
  ],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

