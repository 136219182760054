import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-awards-and-quality-certificates',
  templateUrl: './awards-and-quality-certificates.component.html',
  styleUrls: ['./awards-and-quality-certificates.component.scss']
})
export class AwardsAndQualityCertificatesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
