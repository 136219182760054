import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CompanyComponent} from './company.component';
import { CompanyRoutingModule} from './company-routing.module';


@NgModule({
  declarations: [CompanyComponent ],
  exports: [CompanyComponent ],
  imports: [
    CommonModule, CompanyRoutingModule , RouterModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CompanyModule { }
