import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talent-management',
  templateUrl: './talent-management.component.html',
  styleUrls: ['./talent-management.component.scss']
})
export class TalentManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
