import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AwardsAndQualityCertificatesComponent } from './awards-and-quality-certificates/awards-and-quality-certificates.component';
import { FreeRecruitmentSolutionsComponent } from './free-recruitment-solutions/free-recruitment-solutions.component';
import { HiringFastEasyFreeComponent } from './hiring-fast-easy-free/hiring-fast-easy-free.component';
import { GetUpAndRunningInFiveMiniComponent } from './get-up-and-running-in-five-mini/get-up-and-running-in-five-mini.component';
import { WhySoMuchForFreeComponent } from './why-so-much-for-free/why-so-much-for-free.component';
import { WhyIsRecruitSolutionsMostPopularAtsComponent } from './why-is-recruit-solutions-most-popular-ats/why-is-recruit-solutions-most-popular-ats.component';
import { ViewEveryApplicantFromAllJobPortalComponent } from './view-every-applicant-from-all-job-portal/view-every-applicant-from-all-job-portal.component';
import { TalentVsJobMatchingComponent } from './talent-vs-job-matching/talent-vs-job-matching.component';
import { TalentManagementComponent } from './talent-management/talent-management.component';
import { RecruitSolutionsFastEasyFreeComponent } from './recruit-solutions-fast-easy-free/recruit-solutions-fast-easy-free.component';
import { TrustedByIndustryLeadersComponent } from './trusted-by-industry-leaders/trusted-by-industry-leaders.component';
import { SignUpForNewsletterComponent } from './sign-up-for-newsletter/sign-up-for-newsletter.component';




@NgModule({
  declarations: [AwardsAndQualityCertificatesComponent, FreeRecruitmentSolutionsComponent, HiringFastEasyFreeComponent, GetUpAndRunningInFiveMiniComponent, WhySoMuchForFreeComponent, WhyIsRecruitSolutionsMostPopularAtsComponent, ViewEveryApplicantFromAllJobPortalComponent, TalentVsJobMatchingComponent, TalentManagementComponent, RecruitSolutionsFastEasyFreeComponent, TrustedByIndustryLeadersComponent, SignUpForNewsletterComponent],
  exports: [AwardsAndQualityCertificatesComponent, FreeRecruitmentSolutionsComponent, HiringFastEasyFreeComponent, GetUpAndRunningInFiveMiniComponent, WhySoMuchForFreeComponent, WhyIsRecruitSolutionsMostPopularAtsComponent, ViewEveryApplicantFromAllJobPortalComponent, TalentVsJobMatchingComponent, TalentManagementComponent, RecruitSolutionsFastEasyFreeComponent, TrustedByIndustryLeadersComponent, SignUpForNewsletterComponent],
  imports: [
    CommonModule, RouterModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class HomeModule { }
