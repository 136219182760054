import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talent-vs-job-matching',
  templateUrl: './talent-vs-job-matching.component.html',
  styleUrls: ['./talent-vs-job-matching.component.scss']
})
export class TalentVsJobMatchingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
