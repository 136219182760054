import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruit-solutions-fast-easy-free',
  templateUrl: './recruit-solutions-fast-easy-free.component.html',
  styleUrls: ['./recruit-solutions-fast-easy-free.component.scss']
})
export class RecruitSolutionsFastEasyFreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
